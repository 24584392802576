import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { ArrowButtonComponent } from '@src/components/arrow-button/arrow-button.component';
import { FooterLinkComponent } from '@src/components/footer-link/footer-link.component';
import { PageTitles } from '@src/models/PagesTitles';
import { SharedModule } from '@src/shared.module';

import { filter } from 'rxjs';

@Component({
  standalone: true,
  imports: [SharedModule, ArrowButtonComponent, FooterLinkComponent],
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
})
export class AppFooterComponent implements OnInit {
  public pages = PageTitles;
  public onHome = true;
  public isMaxFooter = false;

  constructor(private _router: Router) {}

  public ngOnInit() {
    this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const currentUrl = this._router.url;
      this.onHome = currentUrl === '/';
      this.isMaxFooter = currentUrl !== '/' && currentUrl !== '/a-propos' && currentUrl !== '/mentions-legales';
    });
  }
}
