<a class="footer-link" [href]="link" [class.small]="isSmall" target="_blank">
  <ng-container *ngIf="withBorder">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </ng-container>
  <i class="footer-link-icon" [class]="iconClass"></i>
  <span class="u-mask">{{ text }}</span>
</a>
