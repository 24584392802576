import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

import { AppFooterComponent } from '@src/components/app-footer/app-footer.component';
import { AppHeaderComponent } from '@src/components/app-header/app-header.component';
import { SharedModule } from '@src/shared.module';

import { StoreService } from 'src/app/services/store.service';

@Component({
  standalone: true,
  imports: [SharedModule, AppHeaderComponent, AppFooterComponent],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private _storeService: StoreService
  ) {}

  public ngOnInit() {
    if (isPlatformBrowser(this._platformId)) {
      console.log('Client-Side Rendering');
    } else {
      console.log('Server-Side Rendering');
    }
    this._storeService.getBackoffice();
  }
}
