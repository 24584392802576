import { convertMarkdownToHTML } from '../utils/conversions';

export interface ModelEnum {
  Code: string;
  Libelle: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function getEnumByCode(code: string, typeEnum: any): ModelEnum {
  const listEnums: ModelEnum[] = Object.values(typeEnum);
  const result = listEnums.find(enumObj => enumObj.Code === code);
  if (!result) console.error('ERROR getEnumByCode : ', code);
  return result || listEnums[0];
}

export class CaractNomEnum {
  public static Aucune: ModelEnum = { Code: '', Libelle: 'Aucune' };
  public static Force: ModelEnum = { Code: 'FOR', Libelle: 'Force' };
  public static Habilete: ModelEnum = { Code: 'HAB', Libelle: 'Habileté' };
  public static Intellect: ModelEnum = { Code: 'INT', Libelle: 'Intellect' };
  public static Tenacite: ModelEnum = { Code: 'TEN', Libelle: 'Ténacité' };
  public static Charisme: ModelEnum = { Code: 'CHA', Libelle: 'Charisme' };
  public static Magie: ModelEnum = { Code: 'MAG', Libelle: 'Magie' };
}

export class CaractDescriptionEnum {
  public static Aucune: ModelEnum = { Code: '', Libelle: 'Aucune' };
  public static Force: ModelEnum = {
    Code: 'FOR',
    Libelle: convertMarkdownToHTML(`
  La **Force**, évalue la puissance brute de votre personnage.
  Cette caractéristique est importante pour les personnages combattants, les sportifs
  et tous ceux qui aiment se sortir de situations dangereuses.
  
  1 : Fragile. Force et endurance limitées, vous vous fatiguez vite.
  2 : Moyen. Vous n'avez de problème qu'avec des tours de force inhabituels.
  3 : Costaud. Vous réalisez des tours de force.
  4 : Fort comme un bœuf. Peu de choses ou de personnes peuvent vous résister.
  5 : Herculéen. Vous rivalisez de puissance avec les ours.
  6 : Titanesque. Seulement les créatures peuvent atteindre ce seuil.`),
  };
  public static Habilete: ModelEnum = {
    Code: 'HAB',
    Libelle: convertMarkdownToHTML(`
  L'**Habileté** représente la capacité du personnage à s'acquitter de tâches de précision, sa finesse,
  sa dextérité, son talent pour viser ainsi que sa rapidité de réaction.
  C'est une aptitude inestimable pour les artisans, les voleurs, les danseurs et les tireurs de tout calibre.
  
  1 : Maladroit. Vous êtes un véritable éléphant dans un magasin de porcelaine.
  2 : Moyen. Au moins, vous ne provoquez que rarement des catastrophes.
  3 : Agile. Vous êtes doué de vos mains, et votre agilité vous sert dans bien des situations.
  4 : Félin. Vos réflexes et votre souplesse tiennent davantage de l'animal que de l'humain.
  5 : Gracieux. Vos mouvements sont tellement fluides qu'ils semblent irréels.
  6 : Prodigieux. Seulement les créatures peuvent atteindre ce seuil.`),
  };
  public static Intellect: ModelEnum = {
    Code: 'INT',
    Libelle: convertMarkdownToHTML(`
  Les capacités intellectuelles de votre personnage, sa capacité à analyser, retenir les informations
  et les réutiliser en temps utile. L'**Intellect** est utile aux tacticiens, aux érudits,
  aux scientifiques et aux enquêteurs.
  
  1 : Simplet. Vous comprenez l'essentiel, mais toutes les subtilités vous échappent.
  2 : Moyen. Vous ne faites pas de miracles, mais vous vous en sortez honorablement.
  3 : Vif. Vous comprenez vite, analysez de manière pertinente et savez vous adapter.
  4 : Brillant. Vous comprenez rapidement ce que certains mettent des années à apprendre.
  5 : Génie. Vous maniez facilement des notions que la majorité des gens sont incapables d'appréhender.
  6 : Érudit. Seulement les créatures peuvent atteindre ce seuil.`),
  };
  public static Tenacite: ModelEnum = {
    Code: 'TEN',
    Libelle: convertMarkdownToHTML(`
  Définition de la résistance physique, la **Ténacité** évalue la constitution de votre personnage.
  Cette caractéristique est importante pour les personnages qui veulent encaisser, ou juste vivre plus longtemps.
  Note : Une **Ténacité** égale à 0 retire un Point de Vie max.
  Note : Une **Ténacité** supérieure à 2 donne un Point de Vie max.
  
  1 : Fragile. Résistance limitée, vous tombez malade facilement.
  2 : Moyen. Votre corps vit sa vie, tranquillement, sans plus.
  3 : Résistant. Vous résistez à quelques coups sans problème.
  4 : Rempart. Peu de choses ou de personnes peuvent vous blesser.
  5 : Intuable. Vous résistez à tout, presque tout.
  6 : Immortel. Seulement les créatures peuvent atteindre ce seuil.`),
  };
  public static Charisme: ModelEnum = {
    Code: 'CHA',
    Libelle: convertMarkdownToHTML(`
  La capacité à s'imposer aux autres, à leur inspirer de la confiance, du respect ou de la crainte,
  ainsi qu'à les manipuler. Le **Charisme** comprend bien plus que la beauté physique,
  et est l'atout principal des dirigeants, des séducteurs et de tous les menteurs invétérés.
  
  1 : Fade. Vous n'inspirez pas les autres et semblez parfois transparent à leurs yeux.
  2 : Moyen. Vous vous faites des amis, comme tout le monde.
  3 : Intéressant. Vous ne laissez pas les gens indifférents, et beaucoup vous apprécient.
  4 : Captivant. Leader-né, votre personnalité attire les autres à vous.
  5 : Fascinant. Vous pourriez amener n'importe qui à faire n'importe quoi pour vous.
  6 : Hypnotisant. Seulement les créatures peuvent atteindre ce seuil.`),
  };
  public static Magie: ModelEnum = {
    Code: 'MAG',
    Libelle: convertMarkdownToHTML(`
  La **Magie** est la caractéristique déterminant le niveau de facilité à utiliser
  les pouvoirs des cristaux du Monde d'Arlénor et parfois à y résister.
  Les grands mages ont évidemment un bon niveau dans cette caractéristique.
  
  0 : Non-Mage. Vous n'avez pas accès à la magie.
  1 : Initié. Vous avez une compréhension de base de la magie mais votre contrôle est instable et imprécis.
  2 : Apprenti. Vous avez commencé à explorer les arcanes de la magie mais votre maîtrise reste limitée.
  3 : Mage. Vous avez acquis une compréhension solide de la magie et pouvez exécuter une variété de sorts avec précision et contrôle.
  4 : Sorcier. Vous manipulez les énergies des cristaux avec une habileté et une puissance impressionnantes.
  5 : Archimage. Vous êtes un maître incontesté de la magie, capable de réaliser des prodiges magiques qui défient l'imagination.
  6 : Sans-limite. Seulement les créatures peuvent atteindre ce seuil.`),
  };
}
