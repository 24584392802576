/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, DestroyRef, Inject, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SharedModule } from '@src/shared.module';

import { StoreService } from 'src/app/services/store.service';

@Component({
  standalone: true,
  imports: [SharedModule],
  selector: 'user-form-modal',
  templateUrl: './user-form.modal.html',
})
export class UserFormModal implements OnInit {
  public form: FormGroup;
  public isInvalid: boolean;

  public currentUser: any;
  public users: any[] = [
    { code: '', libelle: 'Visiteur', description: `Rôle par défaut, accès aux informations sur le site sans aucun risque de spoil.` },
    { code: 'PLAYER', libelle: 'Joueur', description: `Accès à certaines infos sur le site, concernant l'Aventure dont les Journaux de Bord.` },
    { code: 'MASTER', libelle: 'Maitre du Jeu', description: `Accès à toutes les infos sur le site, concernant l'Aventure et ses secrets.` },
    { code: 'ADMIN', libelle: 'Administrateur', description: `Accès à toutes les infos sur le site, ainsi qu'aux enregistrements en base de données.` },
  ];

  public get currentRole(): any {
    return this.users.find(user => user.code === this.form.controls.code.value);
  }

  private _destroyRef = inject(DestroyRef);

  public get isAdmin(): boolean {
    return this.form.controls.code.value === this.users[3].code;
  }

  constructor(
    public dialogRef: MatDialogRef<UserFormModal>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      code: new FormControl(''),
      key: new FormControl(''),
    });
  }

  public ngOnInit() {
    if (this._storeService.isAdmin) this.form.controls.code.setValue(this.users[3].code);
    else if (this._storeService.isMaster) this.form.controls.code.setValue(this.users[2].code);
    else if (this._storeService.isPlayer) this.form.controls.code.setValue(this.users[1].code);
    else this.form.controls.code.setValue(this.users[0].code);
    this._checkForm();
    this._checkUser();

    this.form.controls.code.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this.isInvalid = false;
      this._checkForm();
    });

    this.form.controls.key.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(value => {
      if (value) this.isInvalid = false;
    });
  }

  public submitForm() {
    if (this.isAdmin) {
      const result = this._storeService.setAdminBackoffice(this.form.controls.key.value);
      if (!result) {
        this.isInvalid = true;
        this.form.controls.key.setValue('');
      } else this._checkUser();
    } else {
      this._storeService.setRoleBackoffice(this.form.controls.code.value);
      this._checkUser();
    }
  }

  private _checkForm() {
    if (this.isAdmin) {
      this.form.controls.key.enable();
      this.form.controls.key.setValidators([Validators.required]);
      this.form.updateValueAndValidity();
    } else {
      this.form.controls.key.disable();
      this.form.controls.key.setValidators([]);
      this.form.updateValueAndValidity();
    }
  }

  private _checkUser() {
    this.currentUser = this.users.find(user => user.code === this.form.controls.code.value);
  }
}
