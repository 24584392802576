import { ArlenorDifficulty } from '@src/models/arlenor/ArlenorDifficulty';

import { getListRaces } from '../data/ListRaces';

export class ArlenorRace {
  public name: string;
  public description: string;
  public difficulty: ArlenorDifficulty;
  public infoAge: string;
  public infoAppareance: string;
  public ratioWorld: number;
  public ratioMagic: number;
  public image: string;
  public locations: string[];

  public get code(): string {
    let code = this.name;
    code = code.normalize('NFD').replace(/\p{Diacritic}/gu, '');
    code = code.replace(/\s/g, '');
    return code.toUpperCase();
  }

  constructor(name: string, difficulty: ArlenorDifficulty, ratioWorld: number, ratioMagic: number) {
    this.name = name;
    this.description = '';
    this.difficulty = difficulty;
    this.infoAge = '';
    this.infoAppareance = '';
    this.ratioWorld = ratioWorld;
    this.ratioMagic = ratioMagic;
    this.image = '';
    this.locations = [];
  }

  public static getRace(code: string): ArlenorRace {
    const arlenorRaces = getListRaces();
    const result = arlenorRaces.find(race => race.code === code);
    if (!result) console.error('ERROR getRace : ', code);
    return result || arlenorRaces[0];
  }
}
