import { ArlenorDifficulty } from '@src/models/arlenor/ArlenorDifficulty';

export function getListDifficulties(withImpossible = false): ArlenorDifficulty[] {
  const difficulties = [
    new ArlenorDifficulty(
      'status-ocean',
      'Simple',
      '1 dé gagnant',
      `N'importe qui peut normalement y arriver en un temps réduit.`,
      `Lancer un fruit pourri sur une cible immobile à 5m.`,
      ['1', '2', '3', '4', '5', '6']
    ),
    new ArlenorDifficulty(
      'status-green',
      'Standard',
      '2 dés gagnants',
      `La majorité des personnages peut le faire rapidement, et un spécialiste n'éprouve aucune difficulté.`,
      `Obtenir des informations sur une légende urbaine bien connue.`,
      ['1 ou 2', '3 ou 4', '5 ou 6', '4', '5', '6']
    ),
    new ArlenorDifficulty(
      'status-yellow',
      'Complexe',
      '3 dés gagnants',
      `Quasi impossible sans formation à moins de circonstances favorables ou d'un don particulièrement marqué,
      ces actions n'ont de bonne chance d'être réussies que par les spécialistes.`,
      `Trouver de quoi se nourrir en hiver dans un bois profond.`,
      ['1 ou 2', '3 ou 4', '5 ou 6', '4', '5', '6']
    ),
    new ArlenorDifficulty(
      'status-red',
      'Épique',
      '4 dés gagnants',
      `Complexe même pour les spécialistes, ces actions sont impossibles pour des personnes non formées.
      Seuls les meilleurs experts s'en tirent à bon compte.`,
      `Traverser un océan avec un simple voilier.`,
      ['1 ou 2', '3 ou 4', '5 ou 6', '4', '5', '6']
    ),
    new ArlenorDifficulty(
      'status-violet',
      'Légendaire',
      '5 dés gagnants',
      `Impossible d'arriver à ce stade sans avoir des aptitudes quasi surnaturelles.`,
      ` Au cours d'une réception, voler la couronne de la Reine, qu'elle porte sur la tête,
      au vu de tous et sans se faire remarquer.`,
      ['1 ou 2', '3 ou 4', '5 ou 6', '4', '5', '6']
    ),
  ];
  if (withImpossible) difficulties.push(new ArlenorDifficulty('status-grey', 'Impossible'));
  return difficulties;
}
