<a *ngIf="linkPage && linkParams" [routerLink]="linkPage" [queryParams]="linkParams" mat-stroked-button color="primary">
  <i class="icon-arrow-right"></i>
  {{ linkName }}
</a>

<a *ngIf="linkPage && !linkParams" [routerLink]="linkPage" mat-stroked-button color="primary">
  <i class="icon-arrow-right"></i>
  {{ linkName }}
</a>

<a *ngIf="linkUrl" [href]="linkUrl" mat-stroked-button color="primary" target="_blank" rel="noopener noreferrer">
  <i class="icon-arrow-right"></i>
  {{ linkName }}
</a>
