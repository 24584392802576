import { ArlenorDifficulty } from '@src/models/arlenor/ArlenorDifficulty';
import { ArlenorPower, PowerRanksEnum } from '@src/models/arlenor/ArlenorPower';
import { getListBestiaries } from '@src/models/data/ListBestiaries';

import { ModelAPI } from '../ModelAPI';

import { ArlenorBestiary } from './ArlenorBestiary';

export const CURRENT_CREATURE_VERSION = 2;

export class ArlenorCreature extends ModelAPI {
  // Variables à sauvegarder
  public isBDD: boolean;
  public version: number;
  public guid: string;
  public avatar: string;
  public name: string;

  public codeBestiary: string | null;

  public caractFor: number;
  public caractHab: number;
  public caractInt: number;
  public caractTen: number;
  public caractCha: number;
  public caractMag: number;

  public idsPowers: number[];

  public story: string;
  public description: string;
  public traits: string;

  // Variables dérivées
  public get isVersionOK(): boolean {
    return this.version === CURRENT_CREATURE_VERSION;
  }

  public get initiative(): number {
    return this.caractHab + this.caractInt;
  }

  public get totalCaracts(): number {
    return this.caractFor + this.caractHab + this.caractInt + this.caractTen + this.caractCha + this.caractMag;
  }

  public get healthMax(): number {
    let points = 4;
    if (this.caractTen > 3) points++;
    else if (this.caractTen === 1) points--;
    return points;
  }

  public get profil(): string | null {
    if (this.avatar && this.avatar.indexOf('data') === 0) return this.avatar;
    else if (this.avatar) return 'assets/images_filled/creatures/' + this.avatar + '.png';
    else return 'assets/images_filled/creatures/default.png';
  }

  public get bestiary(): ArlenorBestiary | null {
    if (!this.codeBestiary) return null;
    return ArlenorBestiary.getBestiary(this.codeBestiary);
  }

  constructor() {
    super();
    this.isBDD = false;
    this.version = CURRENT_CREATURE_VERSION;
    this.guid = '';
    this.avatar = '';
    this.name = '';

    this.story = '';
    this.description = '';
    this.traits = '';

    this.caractFor = 1;
    this.caractHab = 1;
    this.caractInt = 1;
    this.caractTen = 1;
    this.caractCha = 1;
    this.caractMag = 0;

    const bestiaries = getListBestiaries();
    this.codeBestiary = bestiaries[0].code;
    this.idsPowers = [];
  }

  public getDifficultyColor(allPowers: ArlenorPower[]) {
    const codeDifficulty = this._getDifficulty(allPowers);
    return ArlenorDifficulty.getDifficulty(codeDifficulty).color;
  }

  public getDifficultyName(allPowers: ArlenorPower[]) {
    const codeDifficulty = this._getDifficulty(allPowers);
    return ArlenorDifficulty.getDifficulty(codeDifficulty).name;
  }

  private _getDifficulty(allPowers: ArlenorPower[]): string {
    const stats = this.totalCaracts; // Min 5 - Max 31
    const powersCommun = allPowers.filter(power => this.idsPowers.includes(power.id) && power.codeRank === PowerRanksEnum.Commun.Code).length; // Min 0 - Max 5
    const powersRare = allPowers.filter(power => this.idsPowers.includes(power.id) && power.codeRank === PowerRanksEnum.Rare.Code).length; // Min 0 - Max 5
    const powersTresRare = allPowers.filter(power => this.idsPowers.includes(power.id) && power.codeRank === PowerRanksEnum.TresRare.Code).length; // Min 0 - Max 5
    const powersUnique = allPowers.filter(power => this.idsPowers.includes(power.id) && power.codeRank === PowerRanksEnum.Unique.Code).length; // Min 0 - Max 5

    const points = stats + powersCommun * 1 + powersRare * 2 + powersTresRare * 3 + powersUnique * 5;
    if (points >= 25) return ArlenorDifficulty.getLegendaire().code;
    else if (points >= 20) return ArlenorDifficulty.getEpique().code;
    else if (points >= 15) return ArlenorDifficulty.getComplexe().code;
    else if (points >= 10) return ArlenorDifficulty.getStandard().code;
    else return ArlenorDifficulty.getSimple().code;
  }
}
