import { ArlenorSpecialities } from '../data/ListSpecialities';
import { ModelAPI } from '../ModelAPI';
import { getEnumByCode, ModelEnum } from '../ModelEnum';

import { ArlenorSpeciality } from './ArlenorSpeciality';

export class ArlenorPower extends ModelAPI {
  // Variables à sauvegarder
  public isVerified: boolean;
  public name: string;
  public description: string;
  public codeType: string;
  public codeSpeciality: string;
  public codeCategory: string;
  public codeRank: string;
  public codeRange: string;
  public codeDuration: string;

  // Variables dérivées
  public get code(): string {
    let code = this.name;
    code = code.normalize('NFD').replace(/\p{Diacritic}/gu, '');
    code = code.replace(/\s/g, '');
    return code.toUpperCase();
  }

  public get image(): string | null {
    if (this.codeType === PowerTypesEnum.Attack.Code) return 'assets/images_filled/powers/attack.png';
    if (this.codeType === PowerTypesEnum.Defense.Code) return 'assets/images_filled/powers/defense.png';
    if (this.codeType === PowerTypesEnum.Heal.Code) return 'assets/images_filled/powers/heal.png';
    if (this.codeType === PowerTypesEnum.Alteration.Code) return 'assets/images_filled/powers/alteration.png';
    if (this.codeType === PowerTypesEnum.Utility.Code) return 'assets/images_filled/powers/utility.png';
    return null;
  }

  public get type(): ModelEnum {
    return getEnumByCode(this.codeType, PowerTypesEnum);
  }

  public get speciality(): ArlenorSpeciality {
    return ArlenorSpecialities.getSpeciality(this.codeSpeciality);
  }

  public get rank(): ModelEnum {
    return getEnumByCode(this.codeRank, PowerRanksEnum);
  }

  public get category(): ModelEnum {
    return getEnumByCode(this.codeCategory, PowerCategoriesEnum);
  }

  public get range(): ModelEnum {
    return getEnumByCode(this.codeRange, PowerRangesEnum);
  }

  public get duration(): ModelEnum {
    return getEnumByCode(this.codeDuration, PowerDurationsEnum);
  }

  constructor() {
    super();
    const specialities = new ArlenorSpecialities();

    this.isVerified = false;
    this.name = '';
    this.description = '';
    this.codeType = PowerTypesEnum.Attack.Code;
    this.codeSpeciality = specialities.Gardien.code;
    this.codeRank = PowerRanksEnum.Commun.Code;
    this.codeCategory = PowerCategoriesEnum.Spell.Code;
    this.codeRange = PowerRangesEnum.Personnelle.Code;
    this.codeDuration = PowerDurationsEnum.Instantanee.Code;
  }
}

export class PowerTypesEnum {
  // Attaque - Pouvoirs qui infligent des dégâts directs aux ennemis.
  // Défense - Pouvoirs qui protègent le lanceur ou ses alliés.
  // Soin - Pouvoirs de guérison pour restaurer les points de vie.
  // Altération - Pouvoirs qui effecte les ennemis ou l'environnement.
  // Utilitaire - Pouvoirs non-combattifs pour diverses situations.

  public static Attack: ModelEnum = { Code: 'OFF', Libelle: `Pouvoir d'Attaque` };
  public static Defense: ModelEnum = { Code: 'DEF', Libelle: 'Pouvoir de Défense' };
  public static Heal: ModelEnum = { Code: 'HEAL', Libelle: 'Pouvoir de Soin' };
  public static Alteration: ModelEnum = { Code: 'ALT', Libelle: `Pouvoir d'Altération` };
  public static Utility: ModelEnum = { Code: 'UTIL', Libelle: 'Pouvoir Utilitaire' };
}

export class PowerCategoriesEnum {
  public static Spell: ModelEnum = { Code: 'SPELL', Libelle: 'Lancé' };
  public static Incanting: ModelEnum = { Code: 'INCANTING', Libelle: 'Incanté' };
  public static Chaneling: ModelEnum = { Code: 'CHANELING', Libelle: 'Canalisé' };
}

export class PowerRanksEnum {
  public static Unique: ModelEnum = { Code: 'S', Libelle: 'X' };
  public static TresRare: ModelEnum = { Code: 'A', Libelle: 'III' };
  public static Rare: ModelEnum = { Code: 'B', Libelle: 'II' };
  public static Commun: ModelEnum = { Code: 'C', Libelle: 'I' };
}

export class PowerRangesEnum {
  public static Personnelle: ModelEnum = { Code: '0', Libelle: 'Personnelle' };
  public static Toucher: ModelEnum = { Code: '1', Libelle: 'Toucher' };
  public static Vision: ModelEnum = { Code: '2', Libelle: 'Vision' };
  public static Zone: ModelEnum = { Code: '3', Libelle: 'Zone' };
  public static Infinie: ModelEnum = { Code: '4', Libelle: 'Infinie' };
}

export class PowerDurationsEnum {
  public static Instantanee: ModelEnum = { Code: '0', Libelle: 'Instantanée' };
  public static Scene: ModelEnum = { Code: '1', Libelle: 'Scène' };
  public static Journee: ModelEnum = { Code: '2', Libelle: 'Journée' };
  public static Illimitee: ModelEnum = { Code: '3', Libelle: 'Illimitée' };
}
