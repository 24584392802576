import { getListDifficulties } from '@src/models/data/ListDifficulties';

export class ArlenorDifficulty {
  public color: string;
  public name: string;
  public test: string;
  public description: string;
  public example: string;
  public scores: string[];

  // Variables dérivées
  public get code(): string {
    let code = this.name;
    code = code.normalize('NFD').replace(/\p{Diacritic}/gu, '');
    code = code.replace(/\s/g, '');
    return code.toUpperCase();
  }

  constructor(color: string, name: string, test = '', description = '', example = '', scores: string[] = []) {
    this.color = color;
    this.name = name;
    this.test = test;
    this.description = description;
    this.example = example;
    this.scores = scores;
  }

  public static getDifficulty(code: string) {
    const arlenorDifficulties = getListDifficulties(true);
    const result = arlenorDifficulties.find(difficulty => difficulty.code === code);
    if (!result) console.error('ERROR getDifficulty : ', code);
    return result || arlenorDifficulties[0];
  }

  public static getSimple() {
    return getListDifficulties(true)[0];
  }
  public static getStandard() {
    return getListDifficulties(true)[1];
  }
  public static getComplexe() {
    return getListDifficulties(true)[2];
  }
  public static getEpique() {
    return getListDifficulties(true)[3];
  }
  public static getLegendaire() {
    return getListDifficulties(true)[4];
  }
  public static getImpossible() {
    return getListDifficulties(true)[5];
  }
}
