import { ArlenorBestiary } from '../arlenor/ArlenorBestiary';

export function getListBestiaries(): ArlenorBestiary[] {
  const bestiaries: ArlenorBestiary[] = [];

  bestiaries.push(new ArlenorBestiary('Animal', `Êtres de la faune naturelle, sans capacité magique.`, 'Exemples : loup, oiseau, poisson...'));
  bestiaries.push(
    new ArlenorBestiary(
      'Magique',
      `Êtres liés à la lumière, souvent beaux, bienveillants ou neutres, souvent dotés de capacités magiques innées.`,
      'Exemples : dryade, fée, dragon...'
    )
  );
  bestiaries.push(
    new ArlenorBestiary(
      'Divinité',
      `Êtres légendaires liés à la lumière ou aux ténèbres, appartenant à la famille des anges et des démons`,
      `Les divinités apparaissent seulement dans la campagne de JDR / l'Aventure, normalement.`
    )
  );
  bestiaries.push(
    new ArlenorBestiary(
      'Monstre',
      `Êtres liés aux ténèbres, souvent difformes, terrifiants, malveillants et dangereux.`,
      'Exemples : mort-vivant, wendigo, harpie...'
    )
  );

  return bestiaries;
}
