import { Routes } from '@angular/router';

import { PageTitles } from './models/PagesTitles';

export const routes: Routes = [
  { path: '', loadComponent: () => import('./views/home/home.view'), title: PageTitles.home },
  { path: 'univers', loadChildren: () => import('./views/universe/universe.routing').then(r => r.routes) },
  { path: 'jeu-de-roles', loadChildren: () => import('./views/roleplay/roleplay.routing').then(r => r.routes) },
  { path: 'celestia', loadChildren: () => import('./views/celestia/celestia.routing').then(r => r.routes) },
  { path: 'mentions-legales', loadComponent: () => import('./views/legacy/legacy.view'), title: PageTitles.legacy },
  { path: 'a-propos', loadComponent: () => import('./views/about/about.view'), title: PageTitles.about },
  { path: 'kit-ui', loadComponent: () => import('./views/kit-ui/kit-ui.view'), title: PageTitles.kitui },
  { path: '*', redirectTo: '/' },
];
