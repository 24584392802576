import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { SupabaseService } from '@src/services/supabase.service';

import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ArlenorArchetype } from '../models/arlenor/ArlenorArchetype';
import { ArlenorCharacter, CURRENT_CHARACTER_VERSION } from '../models/arlenor/ArlenorCharacter';
import { ArlenorCreature, CURRENT_CREATURE_VERSION } from '../models/arlenor/ArlenorCreature';
import { ArlenorPower } from '../models/arlenor/ArlenorPower';
import { ArlenorSkill } from '../models/arlenor/ArlenorSkill';
import { ArlenorStuff } from '../models/arlenor/ArlenorStuff';
import { CelestiaQuizz } from '../models/celestia/CelestiaQuizz';
import { getListStuffs } from '../models/data/ListStuffs';
import random from '../utils/random';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  public isAdmin = false;
  public isMaster = false;
  public isPlayer = false;
  public character = new ArlenorCharacter();
  public creature = new ArlenorCreature();
  public quizz = new CelestiaQuizz();
  public localCharacters: ArlenorCharacter[] = null;
  public localCreatures: ArlenorCreature[] = null;
  public hasModification = false;
  public $allCharacters = new BehaviorSubject<ArlenorCharacter[]>(null);
  public $allCreatures = new BehaviorSubject<ArlenorCreature[]>(null);
  public $allPowers = new BehaviorSubject<ArlenorPower[]>(null);
  public $allSkills = new BehaviorSubject<ArlenorSkill[]>(null);
  public $allStuffs = new BehaviorSubject<ArlenorStuff[]>(null);
  public $allArchetypes = new BehaviorSubject<ArlenorArchetype[]>(null);

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private _supabaseService: SupabaseService
  ) {}

  // --- Backoffice ------------------------------------------------------------------------------
  public getBackoffice() {
    if (!isPlatformBrowser(this._platformId)) return;
    const code = localStorage.getItem('backoffice');
    this.isAdmin = false;
    this.isMaster = false;
    this.isPlayer = false;
    if (code === environment.NG_APP_BO_ADMIN) this.isAdmin = true;
    else if (code === environment.NG_APP_BO_MASTER) this.isMaster = true;
    else if (code === environment.NG_APP_BO_PLAYER) this.isPlayer = true;
  }

  public setAdminBackoffice(code: string) {
    if (code === environment.NG_APP_BO_ADMIN) {
      this._storeBackoffice(code);
      this.isAdmin = true;
      this.isMaster = false;
      this.isPlayer = false;
      return true;
    } else return false;
  }

  public setRoleBackoffice(code: string) {
    if (code === 'ADMIN') return;
    if (code === 'MASTER') {
      this._storeBackoffice(environment.NG_APP_BO_MASTER);
      this.isAdmin = false;
      this.isMaster = true;
      this.isPlayer = false;
    } else if (code === 'PLAYER') {
      this._storeBackoffice(environment.NG_APP_BO_PLAYER);
      this.isAdmin = false;
      this.isMaster = false;
      this.isPlayer = true;
    } else {
      this._storeBackoffice('');
      this.isAdmin = false;
      this.isMaster = false;
      this.isPlayer = false;
    }
  }

  // --- Loads and changes ------------------------------------------------------------------------------
  public async loadAllCharacters(payload = false) {
    if (!this.$allCharacters.value || payload) {
      let allCharacters = await this._supabaseService.getAllCharacter();
      allCharacters = allCharacters.sort((a, b) => a.name.localeCompare(b.name));
      this.$allCharacters.next(allCharacters);
    }
  }

  public async loadAllCreatures(payload = false) {
    if (!this.$allCreatures.value || payload) {
      let allCreatures = await this._supabaseService.getAllCreature();
      allCreatures = allCreatures.sort((a, b) => a.name.localeCompare(b.name));
      this.$allCreatures.next(allCreatures);
    }
  }

  public async loadAllPowers(payload = false) {
    if (!this.$allPowers.value || payload) {
      let allPowers = await this._supabaseService.getAllPower();
      allPowers = allPowers.sort((a, b) => a.name.localeCompare(b.name));
      this.$allPowers.next(allPowers);
    }
  }

  public async loadAllSkills(payload = false) {
    if (!this.$allSkills.value || payload) {
      let allSkills = await this._supabaseService.getAllSkill();
      allSkills = allSkills.sort((a, b) => a.name.localeCompare(b.name));
      this.$allSkills.next(allSkills);
    }
  }

  public async loadAllStuffs(payload = false) {
    if (!this.$allStuffs.value || payload) {
      /* let allStuffs = await api.readAllStuff();
      allStuffs = allStuffs.sort((a, b) => a.name.localeCompare(b.name));
      this.$allStuffs.next(allStuffs);*/
      this.$allStuffs.next(getListStuffs());
    }
  }

  public async loadAllArchetypes(payload = false) {
    if (!this.$allArchetypes.value || payload) {
      let allArchetypes = await this._supabaseService.getAllArchetype();
      allArchetypes = allArchetypes.sort((a, b) => a.name.localeCompare(b.name));
      this.$allArchetypes.next(allArchetypes);
    }
  }

  public changeAllCharacters(payload: ArlenorCharacter[]) {
    const allCharacters = payload.sort((a, b) => a.name.localeCompare(b.name));
    this.$allCharacters.next(allCharacters);
  }
  public changeAllCreatures(payload: ArlenorCreature[]) {
    const allCreatures = payload.sort((a, b) => a.name.localeCompare(b.name));
    this.$allCreatures.next(allCreatures);
  }
  public changeAllPowers(payload: ArlenorPower[]) {
    const allPowers = payload.sort((a, b) => a.name.localeCompare(b.name));
    this.$allPowers.next(allPowers);
  }
  public changeAllSkills(payload: ArlenorSkill[]) {
    const allSkills = payload.sort((a, b) => a.name.localeCompare(b.name));
    this.$allSkills.next(allSkills);
  }
  public changeAllStuffs(payload: ArlenorStuff[]) {
    const allStuffs = payload.sort((a, b) => a.name.localeCompare(b.name));
    this.$allStuffs.next(allStuffs);
  }

  // --- Character ------------------------------------------------------------------------------
  public modifyCharacter(payload: boolean = true) {
    this.hasModification = payload;
  }
  public changeCharacter(payload: ArlenorCharacter) {
    this.character = payload;
  }
  public changeCharacterLevel(payload: ArlenorCharacter) {
    this.character.numLevel = payload.numLevel;
  }
  public changeCharacterRace(payload: ArlenorCharacter) {
    this.character.codeRace = payload.codeRace;
  }
  public changeCharacterCaracts(payload: ArlenorCharacter) {
    this.character.caractFor = payload.caractFor;
    this.character.caractHab = payload.caractHab;
    this.character.caractInt = payload.caractInt;
    this.character.caractTen = payload.caractTen;
    this.character.caractCha = payload.caractCha;
    this.character.caractMag = payload.caractMag;
  }
  public changeCharacterCrystals(payload: ArlenorCharacter) {
    this.character.codeSpeciality01 = payload.codeSpeciality01;
    this.character.idsPowers01 = payload.idsPowers01;
    this.character.codeSpeciality02 = payload.codeSpeciality02;
    this.character.idsPowers02 = payload.idsPowers02;
  }
  public changeCharacterSkills(payload: ArlenorCharacter) {
    this.character.idsSkills = payload.idsSkills;
  }
  public changeCharacterIdentity(payload: ArlenorCharacter) {
    this.character.avatar = payload.avatar;
    this.character.name = payload.name;
    this.character.age = payload.age;
    this.character.gender = payload.gender;
  }
  public changeCharacterDivinity(payload: ArlenorCharacter) {
    this.character.codeDivinity = payload.codeDivinity;
    this.character.divinityPoints = payload.divinityPoints;
    this.character.deathPoints = payload.deathPoints;
  }
  public changeCharacterDetails(payload: ArlenorCharacter) {
    this.character.story = payload.story;
    this.character.description = payload.description;
    this.character.traits = payload.traits;
    this.character.codeAlly = payload.codeAlly;
    this.character.codeEnemy = payload.codeEnemy;
  }
  public resetCharacter() {
    this.character = new ArlenorCharacter();
  }
  public resetGUIDCharacter() {
    this.character.guid = '';
  }
  public loadLocalCharacters() {
    this.localCharacters = this._instanceLocalCharacters();
  }
  public saveLocalCharacter(payload: ArlenorCharacter) {
    if (!isPlatformBrowser(this._platformId)) return;
    const characters = this._instanceLocalCharacters();
    // Si un personnage enregistré a les mêmes données
    const characterSaved = characters.find(character => {
      return character.name === payload.name && character.codeRace === payload.codeRace;
    });
    // Alors on remplace la version sauvegardée
    if (characterSaved) {
      payload.guid = characterSaved.guid;
      const index = characters.findIndex(character => character.guid === payload.guid);
      characters[index] = payload;
    }
    // Sinon on ajoute une nouvelle version
    else {
      payload.guid = random.generateID(20);
      characters.push(payload);
    }
    this.localCharacters = characters;
    const localCharactersString = JSON.stringify(characters);
    localStorage.setItem('localCharacters', localCharactersString);
  }
  public deleteLocalCharacter(payload: string) {
    if (!isPlatformBrowser(this._platformId)) return;
    let characters = this._instanceLocalCharacters();
    characters = characters.filter(character => character.guid !== payload);
    this.localCharacters = characters;
    const localCharactersString = JSON.stringify(characters);
    localStorage.setItem('localCharacters', localCharactersString);
  }

  // --- Creature ------------------------------------------------------------------------------
  public modifyCreature(payload: boolean = true) {
    this.hasModification = payload;
  }
  public changeCreature(payload: ArlenorCreature) {
    this.creature = payload;
  }
  public changeCharacterBestiary(payload: ArlenorCreature) {
    this.creature.codeBestiary = payload.codeBestiary;
  }
  public changeCreatureCaracts(payload: ArlenorCreature) {
    this.creature.caractFor = payload.caractFor;
    this.creature.caractHab = payload.caractHab;
    this.creature.caractInt = payload.caractInt;
    this.creature.caractTen = payload.caractTen;
    this.creature.caractCha = payload.caractCha;
    this.creature.caractMag = payload.caractMag;
  }
  public changeCreatureCrystals(payload: ArlenorCreature) {
    this.creature.idsPowers = payload.idsPowers;
  }
  public changeCreatureIdentity(payload: ArlenorCreature) {
    this.creature.avatar = payload.avatar;
    this.creature.name = payload.name;
    this.creature.story = payload.story;
    this.creature.description = payload.description;
    this.creature.traits = payload.traits;
  }
  public resetCreature() {
    this.creature = new ArlenorCreature();
  }
  public resetGUIDCreature() {
    this.creature.guid = '';
  }
  public loadLocalCreatures() {
    this.localCreatures = this._instanceLocalCreatures();
  }
  public saveLocalCreature(payload: ArlenorCreature) {
    if (!isPlatformBrowser(this._platformId)) return;
    const creatures = this._instanceLocalCreatures();
    // Si une créature enregistrée a le même nom
    const creatureSaved = creatures.find(creature => creature.name === payload.name);
    // Alors on remplace la version sauvegardée
    if (creatureSaved) {
      payload.guid = creatureSaved.guid;
      const index = creatures.findIndex(creature => creature.name === payload.name);
      creatures[index] = payload;
    }
    // Sinon on ajoute une nouvelle version
    else {
      payload.guid = random.generateID(20);
      creatures.push(payload);
    }
    this.localCreatures = creatures;
    const localCreaturesString = JSON.stringify(creatures);
    localStorage.setItem('localCreatures', localCreaturesString);
  }
  public deleteLocalCreature(payload: string) {
    if (!isPlatformBrowser(this._platformId)) return;
    let creatures = this._instanceLocalCreatures();
    creatures = creatures.filter(creature => creature.guid !== payload);
    this.localCreatures = creatures;
    const localCreaturesString = JSON.stringify(creatures);
    localStorage.setItem('localCreatures', localCreaturesString);
  }

  // --- Quizz ------------------------------------------------------------------------------
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public changeQuizz(payload: any /* Note : { index, quizz } */) {
    this.quizz.questions[payload.index] = payload.quizz.questions[payload.index];
  }

  // --- Algorithmes ------------------------------------------------------------------------------
  private _instanceLocalCharacters(): ArlenorCharacter[] {
    if (!isPlatformBrowser(this._platformId)) return [];
    let localCharactersString = localStorage.getItem('localCharacters');

    let localCharacters: ArlenorCharacter[];
    if (localCharactersString) localCharacters = JSON.parse(localCharactersString);
    else localCharacters = [];

    const finalCharacters: ArlenorCharacter[] = [];
    localCharacters.forEach(character => {
      const item = new ArlenorCharacter();
      // Si la version sauvegardée est celle actuelle
      // Alors aucun soucis, on la prend !
      if (character.version === CURRENT_CHARACTER_VERSION) {
        Object.assign(item, character);
        finalCharacters.push(item);
      }
      // Sinon, on prend les propriétés qui ne changeront pas...
      else {
        item.version = character.version;
        item.numLevel = character.numLevel;
        item.guid = character.guid;
        item.name = character.name;
        finalCharacters.push(item);
      }
    });

    // On sauvegarde le nettoyage
    localCharactersString = JSON.stringify(finalCharacters);
    localStorage.setItem('localCharacters', localCharactersString);
    return finalCharacters;
  }

  private _instanceLocalCreatures(): ArlenorCreature[] {
    if (!isPlatformBrowser(this._platformId)) return [];
    let localCreaturesString = localStorage.getItem('localCreatures');

    let localCreatures: ArlenorCreature[];
    if (localCreaturesString) localCreatures = JSON.parse(localCreaturesString);
    else localCreatures = [];

    const finalCreatures: ArlenorCreature[] = [];
    localCreatures.forEach(creature => {
      const item = new ArlenorCreature();
      // Si la version sauvegardée est celle actuelle
      // Alors aucun soucis, on la prend !
      if (creature.version === CURRENT_CREATURE_VERSION) {
        Object.assign(item, creature);
        finalCreatures.push(item);
      }
      // Sinon, on prend les propriétés qui ne changeront pas...
      else {
        item.version = creature.version;
        item.guid = creature.guid;
        item.name = creature.name;
        finalCreatures.push(item);
      }
    });

    // On sauvegarde le nettoyage
    localCreaturesString = JSON.stringify(finalCreatures);
    localStorage.setItem('localCreatures', localCreaturesString);
    return finalCreatures;
  }

  // --- Autres ------------------------------------------------------------------------------
  private _storeBackoffice(code: string) {
    if (!isPlatformBrowser(this._platformId)) return;
    localStorage.setItem('backoffice', code);
  }
}
