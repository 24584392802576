import { Component, Input } from '@angular/core';

import { SharedModule } from '@src/shared.module';

@Component({
  standalone: true,
  imports: [SharedModule],
  selector: 'arrow-button',
  templateUrl: './arrow-button.component.html',
  styles: [':host { display: contents; }'],
})
export class ArrowButtonComponent {
  @Input() public linkName: string;
  @Input() public linkPage: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public linkParams: any;
  @Input() public linkUrl: string;
}
