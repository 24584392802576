import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { createClient, SupabaseClient } from '@supabase/supabase-js';

import { ArlenorArchetype } from '@src/models/arlenor/ArlenorArchetype';
import { ArlenorCharacter } from '@src/models/arlenor/ArlenorCharacter';
import { ArlenorCreature } from '@src/models/arlenor/ArlenorCreature';
import { ArlenorPower } from '@src/models/arlenor/ArlenorPower';
import { ArlenorSkill } from '@src/models/arlenor/ArlenorSkill';
import { ArlenorStuff } from '@src/models/arlenor/ArlenorStuff';
import { CelestiaQuizz } from '@src/models/celestia/CelestiaQuizz';
import { ModelAPI } from '@src/models/ModelAPI';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SupabaseService {
  private supabase: SupabaseClient | null = null;

  constructor(@Inject(PLATFORM_ID) private _platformId: object) {
    if (isPlatformBrowser(this._platformId)) {
      const url = environment.NG_APP_SUPABASE_URL || '';
      const key = environment.NG_APP_SUPABASE_ANON_KEY || '';
      this.supabase = createClient(url, key);
    }
  }

  // --- QUIZZ ---------------------------------------------------------------------------
  public getAllQuizz = async (): Promise<CelestiaQuizz[]> => {
    return await this._requestGet('quizz');
  };

  public postQuizz = async (quizz: CelestiaQuizz): Promise<number> => {
    quizz.initTime();
    return await this._requestPost('quizz', quizz);
  };

  // --- CHARACTER ---------------------------------------------------------------------------
  public getAllCharacter = async (): Promise<ArlenorCharacter[]> => {
    const result = await this._requestGet('character');
    return this.transformCharacters(result);
  };

  public postCharacter = async (character: ArlenorCharacter): Promise<number> => {
    character.initTime();
    character = Object.assign({}, character);
    character.avatar = 'default';
    return await this._requestPost('character', character);
  };

  public putCharacter = async (character: ArlenorCharacter): Promise<boolean> => {
    character.initTime();
    return await this._requestPut('character', character);
  };

  public deleteCharacter = async (character: ArlenorCharacter): Promise<boolean> => {
    return await this._requestDelete('character', character.id);
  };

  public transformCharacters = (characters: ArlenorCharacter[]): ArlenorCharacter[] => {
    const items: ArlenorCharacter[] = [];
    characters.forEach(character => {
      const item = new ArlenorCharacter();
      Object.assign(item, character);
      items.push(item);
    });
    return items;
  };

  // --- CREATURE ---------------------------------------------------------------------------
  public getAllCreature = async (): Promise<ArlenorCreature[]> => {
    const result = await this._requestGet('creature');
    return this.transformCreatures(result);
  };

  public postCreature = async (creature: ArlenorCreature): Promise<number> => {
    creature.initTime();
    creature = Object.assign({}, creature);
    creature.avatar = 'default';
    return await this._requestPost('creature', creature);
  };

  public putCreature = async (creature: ArlenorCreature): Promise<boolean> => {
    creature.initTime();
    return await this._requestPut('creature', creature);
  };

  public deleteCreature = async (creature: ArlenorCreature): Promise<boolean> => {
    return await this._requestDelete('creature', creature.id);
  };

  public transformCreatures = (creatures: ArlenorCreature[]): ArlenorCreature[] => {
    const items: ArlenorCreature[] = [];
    creatures.forEach(creature => {
      const item = new ArlenorCreature();
      Object.assign(item, creature);
      items.push(item);
    });
    return items;
  };

  // --- ARCHETYPE ---------------------------------------------------------------------------
  public getAllArchetype = async (): Promise<ArlenorArchetype[]> => {
    const result = await this._requestGet('archetype');
    return this.transformArchetypes(result);
  };

  public transformArchetypes = (archetypes: ArlenorArchetype[]): ArlenorArchetype[] => {
    const items: ArlenorArchetype[] = [];
    archetypes.forEach(archetype => {
      const item = new ArlenorArchetype();
      Object.assign(item, archetype);
      items.push(item);
    });
    return items;
  };

  // --- SKILL ---------------------------------------------------------------------------
  public getAllSkill = async (): Promise<ArlenorSkill[]> => {
    const result = await this._requestGet('skill');
    return this.transformSkills(result);
  };

  public postSkill = async (skill: ArlenorSkill): Promise<number> => {
    skill.initTime();
    return await this._requestPost('skill', skill);
  };

  public putSkill = async (skill: ArlenorSkill): Promise<boolean> => {
    skill.initTime();
    return await this._requestPut('skill', skill);
  };

  public deleteSkill = async (skill: ArlenorSkill): Promise<boolean> => {
    return await this._requestDelete('skill', skill.id);
  };

  public transformSkills = (skills: ArlenorSkill[]): ArlenorSkill[] => {
    const items: ArlenorSkill[] = [];
    skills.forEach(skill => {
      const item = new ArlenorSkill();
      Object.assign(item, skill);
      items.push(item);
    });
    return items;
  };

  // --- STUFF ---------------------------------------------------------------------------
  public getAllStuff = async (): Promise<ArlenorStuff[]> => {
    return await this._requestGet('stuff');
  };

  public postStuff = async (stuff: ArlenorStuff): Promise<number> => {
    stuff.initTime();
    return await this._requestPost('stuff', stuff);
  };

  public putStuff = async (stuff: ArlenorStuff): Promise<boolean> => {
    stuff.initTime();
    return await this._requestPut('stuff', stuff);
  };

  public deleteStuff = async (stuff: ArlenorStuff): Promise<boolean> => {
    return await this._requestDelete('stuff', stuff.id);
  };

  // --- POWER ---------------------------------------------------------------------------
  public getAllPower = async (): Promise<ArlenorPower[]> => {
    const result = await this._requestGet('power');
    return this.transformPowers(result);
  };

  public postAllPower = async (powers: ArlenorPower[]): Promise<ArlenorPower[]> => {
    powers.forEach(power => power.initTime());
    const result: ArlenorPower[] = await this._requestPostAll('power', powers);
    return this.transformPowers(result);
  };

  public postPower = async (power: ArlenorPower): Promise<number> => {
    power.initTime();
    return await this._requestPost('power', power);
  };

  public putPower = async (power: ArlenorPower): Promise<boolean> => {
    power.initTime();
    return await this._requestPut('power', power);
  };

  public deletePower = async (power: ArlenorPower): Promise<boolean> => {
    return await this._requestDelete('power', power.id);
  };

  public transformPowers = (powers: ArlenorPower[]): ArlenorPower[] => {
    const items: ArlenorPower[] = [];
    powers.forEach(power => {
      const item = new ArlenorPower();
      Object.assign(item, power);
      items.push(item);
    });
    return items;
  };

  private async _requestGet(target: string): Promise<any[]> {
    if (!this.supabase) return [];
    const { data } = await this.supabase.from(target).select();
    // console.log('requestGet', data);
    return data || [];
  }

  private async _requestPostAll(target: string, items: ModelAPI[]): Promise<any[]> {
    if (!this.supabase) return [];
    const newItems: any[] = [];
    items.forEach(item => {
      const newItem: any = Object.assign({}, item);
      delete newItem['id'];
      newItems.push(newItem);
    });
    const { data } = await this.supabase.from(target).insert(newItems).select();
    // console.log('requestPostAll', data);
    return data || [];
  }

  private async _requestPost(target: string, item: ModelAPI): Promise<number> {
    if (!this.supabase) return 0;
    const newItem: any = Object.assign({}, item);
    delete newItem['id'];
    const { data } = await this.supabase.from(target).insert([newItem]).select();
    const id = data && data[0].id ? data[0].id : 0;
    // console.log('requestPost', id);
    return id;
  }

  private async _requestPut(target: string, item: ModelAPI): Promise<boolean> {
    if (!this.supabase) return false;
    await this.supabase.from(target).update(item).eq('id', item.id).select();
    // console.log('requestPut');
    return true;
  }

  private async _requestDelete(target: string, id: number | null): Promise<boolean> {
    if (!this.supabase) return false;
    await this.supabase.from(target).delete().eq('id', id).select();
    // console.log('requestDelete');
    return true;
  }
}
